import React from "react";


const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6">
                            <div className="footer-widget">
                                <img src="images/logo-light.webp" alt=""/>
                                <div className="about-widget">
                                    <p>Nous sommes spécialisés dans le transport et la logistique maritime,
                                        aérien et terrestre nous proposons un service d’importation
                                        et d’exportation de L’Europe vers l’Afrique et vice versa.</p>
                                    <ul className="list-unstyled">
                                        <li><a href="https://www.facebook.com/Transportair-108371054268302" rel="noopener noreferrer"
                                               target="_blank"><i className="fa fa-facebook"></i></a></li>
                                        <li><a href=""><i className="fa fa-twitter"></i></a></li>
                                        <li><a href=""><i className="fa fa-linkedin"></i></a></li>
                                        <li><a href=""><i className="fa fa-google-plus"></i></a></li>
                                        <li><a href=""><i className="fa fa-youtube"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-widget">
                                <h2>TRANS-PORT-AIR</h2>
                                <div className="custom-link-widget">
                                    <ul className="list-unstyled">
                                        <li><i className="fa fa-angle-right"></i> <a href="/a-propos">Qui
                                            sommes-nous?</a>
                                        </li>
                                        <li><i className="fa fa-angle-right"></i> <a href="">E-Commerce</a></li>
                                        <li><i className="fa fa-angle-right"></i> <a href="">Points relais</a></li>
                                        <li><i className="fa fa-angle-right"></i> <a href="/cgu">Charte client</a></li>
                                        <li><i className="fa fa-angle-right"></i> <a href="/contact">Laissez un
                                            message</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-widget">
                                <h2>Contact</h2>
                                <div className="contact-widget">
                                    <ul className="list-unstyled">
                                        <li><a
                                            href="https://www.google.com/maps/place/71+Rue+Etienne+Dolet,+94140+Alfortville/@48.793266,2.4234554,17z/data=!3m1!4b1!4m5!3m4!1s0x47e6736b328dea27:0xd670a6ec9a53e43e!8m2!3d48.7932625!4d2.4256441">
                                            <div className="icon"><i className="fa fa-map"></i></div>
                                            <div className="text">71 Rue Etienne Dolet 94140 Alfortville, France</div>
                                        </a>
                                        </li>
                                        <li>
                                            <div className="icon"><i className="fa fa-clock-o"></i></div>
                                            <div className="text">Lundi &ndash; Vendredi: 09:00 &ndash; 21:00 Samedi:
                                                09:00
                                                &ndash; 18:00 <br/>Dimanche: Fermé
                                            </div>
                                        </li>
                                        <li><a href="mailto:transportairservices@gmail.com">
                                            <div className="icon"><i className="fa fa-envelope"></i></div>
                                            <div className="text">transportairservices @gmail.com</div>
                                        </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-12">
                            <div className="footer-bottom">
                                <p>© 2020 <a href="/">TRANS-PORT-AIR</a> | Créé avec <i className="fa fa-heart-o"></i> &
                                    Tout
                                    droit réservé</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="#" id="back-to-top" title="Back to top"><i className="fa fa-arrow-up"></i></a>
        </footer>
    )
};

export default Footer;
