import React from 'react';
import ReactDOM from 'react-dom';

import './assets/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import './assets/css/meanmenu.css';
import './assets/css/normalize.css';
import './assets/css/style.css';
import './assets/css/responsive.css';

import 'owl.carousel';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {HelmetProvider} from 'react-helmet-async';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <App/>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
