import app from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/analytics'
import 'firebase/storage'

const settings = {timestampsInSnapshots: true};

const firebaseConfig = {
  apiKey: "AIzaSyCKfKWQQf9yv02PAZZHSOyHvXzHQ5jLOKI",
  authDomain: "trans-port-air.firebaseapp.com",
  databaseURL: "https://trans-port-air.firebaseio.com",
  projectId: "trans-port-air",
  storageBucket: "trans-port-air.appspot.com",
  messagingSenderId: "922850973079",
  appId: "1:922850973079:web:ba9d0c7447d6fbd141e0d2",
  measurementId: "G-H9PD4ENEEH"
};

app.initializeApp(firebaseConfig);

app.functions()//.useFunctionsEmulator('http://localhost:5000');

app.analytics();

app.firestore().settings(settings);

let firebase = app;

export default firebase;
