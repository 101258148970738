import React from "react";
import {Link, NavLink} from "react-router-dom";
import {activeStyle} from "../helpers/styles";


const Header = () => {
  return (
    <header>
      <div className="header-top-bar">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <ul className="list-unstyled bar-contact">
                <li><a href="tel:+33672899602"><i className="fa fa-phone"/></a> +33 672-899-602</li>
                <li><a href="mailto:transportairservices@gmail.com"><i className="fa fa-envelope"/></a>transportairservices@gmail.com
                </li>
                <li><i className="fa fa-clock-o"/> 09:00 - 21:00</li>
              </ul>
            </div>
            <div className="col-sm-4">
              <ul className="list-unstyled bar-social">
                <li><a href="https://www.facebook.com/Transportair-108371054268302" rel="noopener noreferrer"
                       target="_blank"><i
                  className="fa fa-facebook"/></a></li>
                <li><a href="#"><i className="fa fa-twitter"/></a></li>
                <li><a href="#"><i className="fa fa-linkedin"/></a></li>
                <li><a href="#"><i className="fa fa-youtube"/></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="header-main">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <nav className="clearfix">
                <div className="row">
                  <div className="col-lg-3 col-sm-12 brand-logo">
                    <Link className="logo" to="/" onClick={(e) => {
                      e.preventDefault();
                      window.location.href = '/'
                    }}><img src="images/logo.webp" alt=""/></Link>
                  </div>
                  <div className="col-lg-9 col-sm-12" id="cruise-menu">
                    <ul className="mr-auto">
                      <li className="nav-item">
                        <NavLink to="/tarifs" activeStyle={activeStyle} className="nav-link">Tarifs</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink activeStyle={activeStyle} className="nav-link" to="/suivi-colis">Suivi colis</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink activeStyle={activeStyle} className="nav-link" to="/commandez-un-produit">Commandez un
                          produit</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink activeStyle={activeStyle} className="nav-link" to="/a-propos">A propos</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink activeStyle={activeStyle} className="nav-link" to="/contact">Contact</NavLink>
                      </li>
                      <li className="nav-item">
                          <button type="button" className="btn btn-custom txt-send-no-mobile"
                                  onClick={() => window.location.href = '/expedier-un-colis'}>
                            Expedier un colis
                          </button>
                          <a href="/expedier-un-colis" className="nav-link txt-send-mobile d-none">
                              Expedier un colis
                          </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
};

export default Header;
