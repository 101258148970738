import React from "react";

export default function Banniere(props) {
  return (
    <>
      <div className="d-none d-md-block">
        <section className="slider owl-carousel intro-slider">
          <div className="intro">
            <figure>
              <img src="images/slide3.webp" alt="Expédier un colis" className="img-fluid"/>
              <figcaption>
                <div className="container">
                  <div className="intro-details">
                    <div className="intro-info">
                      <h2 dangerouslySetInnerHTML={{__html: props.value.title1 ? props.value.title1 : "<span>LE MONDE À PORTÉ DE MAIN</span>"}}/>
                      <p dangerouslySetInnerHTML={{__html: props.value.desc1 ? props.value.desc1 : "Nous nous efforçons à supprimer les barrières entre les frontières en vous offrant un\n" +
                          "                service de haute qualité garantissant Rapidité, Simplicité et Fiabilité."}}/>
                      <ul className="list-unstyled clearfix">
                        <li><span/><br/><span/></li>
                        <li><a href="/expedier-un-colis" className="btn-hover">Expédier un
                          colis</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
          <div className="intro">
            <figure>
              <img src="images/slide2.webp" alt="Expédier un colis" className="img-fluid"/>
              <figcaption>
                <div className="container">
                  <div className="intro-details">
                    <div className="intro-info">
                      <h2 dangerouslySetInnerHTML={{__html: props.value.title2}}/>
                      <p dangerouslySetInnerHTML={{__html: props.value.desc2}}/>
                      <ul className="list-unstyled clearfix">
                        <li><span></span><br/><span></span></li>
                        <li><a href="/expedier-un-colis" className="btn-hover">Expédier un
                          colis</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
          <div className="intro">
            <figure>
              <img src="images/slide4.webp" alt="Commandez un produit" className="img-fluid"/>
              <figcaption>
                <div className="container">
                  <div className="intro-details">
                    <div className="intro-info">
                      <h2 dangerouslySetInnerHTML={{__html: props.value.title3}}/>
                      <p dangerouslySetInnerHTML={{__html: props.value.desc3}}/>
                      <ul className="list-unstyled clearfix">
                        <li><span/><br/><span/></li>
                        <li><a href="/commandez-un-produit" className="btn-hover">Commandez
                          un produit</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </section>
      </div>
      <section className="intro d-md-none">
        <div className="container">
          <div className="intro-details">
            <div className="intro-info">
              <h2><span>LE MONDE À PORTÉ DE MAIN</span></h2>
              <p>Nous nous efforçons à supprimer les barrières entre les frontières en vous offrant un
                service de haute qualité garantissant Rapidité, Simplicité et Fiabilité.</p>
              <ul className="list-unstyled clearfix">
                <li>
                  <a href="/expedier-un-colis" className="btn-hover">Expedier un colis</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
