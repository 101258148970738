import React, {lazy} from "react";
import firebase from '../Firebase'
import {Helmet} from "react-helmet-async";
import Banniere from "./Banniere";
const Ordering = lazy(() => import("./Ordering"));
const HomeSuiviColis = lazy(() => import("./HomeSuiviColis"));
const HomeOffers = lazy(() => import("./HomeOffers"));
const HomeAboutUs = lazy(() => import("./HomeAboutUs"));
const HomeWhyChooseUs = lazy(() => import("./HomeWhyChooseUs"));

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      home: {}
    };
  }

  componentDidMount() {
    firebase.firestore().collection('admin').doc("home").get().then((doc) => {
      if (doc.exists) {
        this.setState({
          home: doc.data()
        })
      }
    })
  }

  render() {
    const {home} = this.state;
    return (
      <section>
        <Helmet>
          <title>Envoi de colis France vers Guinée Conakry - Exportation vers l’Afrique</title>
          <meta name="keywords" content="Envoi de colis France vers Guinée Conakry"/>
          <meta name="description"
                content="Envoi de colis France vers Guinée Conakry en express - expédition aérien et maritime de vos colis de la France vers l’Afrique"/>
        </Helmet>
        <Banniere value={home}/>
        <HomeSuiviColis/>
        <HomeWhyChooseUs/>
        <HomeAboutUs/>
        <HomeOffers/>
        <Ordering/>
      </section>
    )
  }
}
