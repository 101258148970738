import React, {lazy, Suspense} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from "./component/Home";
import Header from "./component/Header";
import Footer from "./component/Footer";
import Script from 'react-load-script'

const Tarifs = lazy(() => import("./component/Tarifs"));
const SuivisColis = lazy(() => import("./component/SuivisColis"));
const OrderPage = lazy(() => import("./component/OrderPage"));
const AboutUs = lazy(() => import("./component/AboutUs"));
const Contact = lazy(() => import("./component/Contact"));
const SendColis = lazy(() => import("./component/SendColis"));
const Cgu = lazy(() => import("./component/Cgu"));
const Success = lazy(() => import("./component/Success"));

export default function App() {
  return (
    <Router>
      <Script url="js/umd/popper.min.js"/>
      <Script url="js/bootstrap.min.js"/>
      <Script url="js/jquery.sticky.js"/>
      <Script url="js/jquery.meanmenu.min.js"/>
      <Script url="js/custom.js"/>
      <div>
        <Header/>
        <Suspense fallback={<div id="prelaoder">
          <div className="spinner">
            <div className="spinner-inner"></div>
          </div>
        </div>}>
          <Switch>
            <Route path="/success/:colis" children={<Success/>}/>
            <Route path="/tarifs">
              <Tarifs/>
            </Route>
            <Route path="/suivi-colis">
              <SuivisColis/>
            </Route>
            <Route path="/commandez-un-produit">
              <OrderPage/>
            </Route>
            <Route path="/a-propos">
              <AboutUs/>
            </Route>
            <Route path="/contact">
              <Contact/>
            </Route>
            <Route path="/expedier-un-colis">
              <SendColis/>
            </Route>
            <Route path="/cgu">
              <Cgu/>
            </Route>
            <Route path="/">
              <Home/>
            </Route>
          </Switch>
        </Suspense>
        <Footer/>
      </div>
    </Router>
  );
}

